export default {
  // PAVR
  PavrRegistrationForm: () => import('./pavr/purchase-and-registration/registration_form'),
  PavrMemberInformationReport: () => import('./pavr/reports/member_information_report/display'),
  PavrLoginHeader: () => import('./pavr/login/header'),
  PavrRegistrationPointer: () => import('./pavr/login/registration-pointer'),
  PavrLoginFooter: () => import('./pavr/login/footer'),
  AacapLoginHeader: () => import('./aacap/login/header'),
  AacapRegistrationPointer: () => import('./aacap/login/registration-pointer')
}
