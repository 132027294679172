const state = {
  questions:                     [],
  questionOrderSequence:         [],
  bookmarkedQuestions:           [],
  unansweredQuestions:           [],
  currentQuestion:               [],
  selectedQuestionsUnderReview:  [],
  questionReviewType:            'UNANSWERED',
  underReview:                   false,
  showUnansweredQuestionWarning: false
}

const actions = {
  INITIALIZE_QUESTIONS: function({ commit }, value) {
    commit('setQuestions', value)
    commit('setCurrentQuestion', 1)
  },
  INITIALIZE_UNANSWERED_QUESTIONS: function({ commit }, value) {
    commit('initializeUnansweredQuestions', value)
  },
  SET_QUESTIONS: function({ commit }, value) {
    commit('setQuestions', value)
  },
  SET_BOOKMARKED_QUESTIONS: function({ commit }, value) {
    commit('setBookmarkedQuestions', value)
  },
  BOOKMARK_QUESTION: function({ commit }, value) {
    commit('bookmarkQuestion', value)
  },
  REMOVE_QUESTION_FROM_BOOKMARKS: function({ commit }, value) {
    commit('removeQuestionFromBookmarks', value)
  },
  MARK_QUESTION_ANSWERED: function({ commit }, value) {
    commit('markQuestionAnswered', value)
  },
  REVIEW_BOOKMARKED_QUESTIONS({ commit }) {
    commit('reviewBookmarkedQuestions')
  },
  REVIEW_UNANSWERED_QUESTIONS({ commit }) {
    commit('reviewUnansweredQuestions')
  },
  REVIEW_ALL_QUESTIONS({ commit }) {
    commit('reviewAllQuestions')
  },
  SET_CURRENT_QUESTION({ commit }, value) {
    commit('setCurrentQuestion', value)
  },
  REVIEW_EXAM({ commit }) {
    commit('reviewExam')
  },
  LEAVE_EXAM_REVIEW({ commit }) {
    commit('leaveExamReview')
  },
  LEAVE_UNANSWERED_QUESTION_WARNING({ commit }) {
    commit('leaveUnansweredQuestionWarning')
  },
  DISPLAY_UNANSWERED_QUESTION_WARNING({ commit }) {
    commit('displayUnansweredQuestionWarning')
  },
  UPDATE_SELECTED_QUESTIONS_UNDER_REVIEW({ commit }, value) {
    commit('updateSelectedQuestionsUnderReview', value)
  }
}

const mutations = {
  setQuestions: function(state, value) {
    state.questions = value
    state.questionOrderSequence = value.map(q => q.data.order_sequence)
  },
  initializeUnansweredQuestions: function(state, value) {
    state.unansweredQuestions = value
  },
  setBookmarkedQuestions: function(state, value) {
    state.bookmarkedQuestions = value
  },
  bookmarkQuestion: function(state, value) {
    if (!state.bookmarkedQuestions.includes(value)) {
      state.bookmarkedQuestions.push(value)
    }
  },
  removeQuestionFromBookmarks: function(state, value) {
    state.bookmarkedQuestions = state.bookmarkedQuestions.filter(q =>
      q !== value
    )
  },
  markQuestionAnswered: function(state, value) {
    state.unansweredQuestions = state.unansweredQuestions.filter(q =>
      q !== value
    )
  },
  reviewBookmarkedQuestions: function(state, value) {
    state.questionReviewType = 'BOOKMARKED'

  },
  reviewUnansweredQuestions: function(state, value) {
    state.questionReviewType = 'UNANSWERED'
  },
  reviewAllQuestions: function(state) {
    state.questionReviewType = 'ALL'
  },
  setCurrentQuestion: function(state, value) {
    state.currentQuestion = value
  },
  reviewExam: function(state) {
    state.underReview = true
  },
  leaveExamReview: function(state) {
    state.underReview = false
  },
  displayUnansweredQuestionWarning: function(state) {
    state.showUnansweredQuestionWarning = true
  },
  leaveUnansweredQuestionWarning: function(state) {
    state.showUnansweredQuestionWarning = false
  },
  updateSelectedQuestionsUnderReview: function(state, value) {
    state.selectedQuestionsUnderReview = value
  }
}

export default {
  state,
  actions,
  mutations
}
