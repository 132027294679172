const state = {
  readyToPurchase: false,
  defaultCheckout: false,
  appliedCouponDetails: null,
  productID: null
}

const actions = {
  SET_DEFAULT_CHECKOUT: function({ commit }, value) {
    commit('setDefaultCheckout')
    commit('setProductID', value)
  },
  UNSET_DEFAULT_CHECKOUT: function({ commit }, value) {
    commit('unsetDefaultCheckout')
    commit('setProductID', null)
  },
  SET_READY_TO_PURCHASE: function({ commit }, value) {
    commit('setReadyToPurchase')
    commit('setProductID', value)
  },
  UNSET_READY_TO_PURCHASE: function({ commit }) {
    commit('unsetReadyToPurchase')
    commit('setProductID', null)
  },
  CLEAR_COUPONS: function({ commit }) {
    commit('clearCoupons')
  },
  UNSET_DEFAULT_CHECKOUT: function({ commit }) {
    commit('unsetDefaultCheckout')
  }
}

const mutations = {
  setDefaultCheckout: function(state) {
    state.defaultCheckout = true
  },
  setAppliedCouponDetails: function(state, payload) {
    state.appliedCouponDetails = payload
  },
  unsetDefaultCheckout: function(state) {
    state.defaultCheckout = false
  },
  setReadyToPurchase: function(state) {
    state.readyToPurchase = true
  },
  unsetReadyToPurchase: function(state) {
    state.readyToPurchase = false
  },
  setProductID: function(state, value) {
    state.productID = value
  },
  clearCoupons: function(state) {
    state.appliedCouponDetails = null
  }
}

const getters = {
  productPriceWithDiscount: (state) => (productPriceCents) => {
    if (!state.appliedCouponDetails) return productPriceCents

    const {attributes} = state.appliedCouponDetails.data;
    if (attributes.discount_type == 'percent') {
      return productPriceCents - ((productPriceCents * attributes.discount_value) / 100)
    } else {
      return productPriceCents - (attributes.discount_value * 100)
    }
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
