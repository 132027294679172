import SharedComponents from './shared/main'
import DashboardComponents from './dashboard/main'
import ShopComponents from './shop/main'
import ExamComponents from './exam/main'
import VendorComponents from './vendor/main'
import AdminComponents from './admin/main'

export default {
  Topbar: () => import('./topbar'),
  ...SharedComponents,
  ...DashboardComponents,
  ...ShopComponents,
  ...ExamComponents,
  ...VendorComponents,
  ...AdminComponents
}
