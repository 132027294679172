import { SafeGet } from '@components/shared/safe-get'

const triggerMutation = function(mutation) {
  return function({ commit }, value) {
    commit(mutation, value)
  }
}

const state = {
  pavrUser: null
}

const actions = {
  SET_PAVR_USER:                              triggerMutation('setPAVRUser'),
  UPDATE_PAVR_USER_FIRST_NAME:                triggerMutation('updatePAVRUserFirstName'),
  UPDATE_PAVR_USER_LAST_NAME:                 triggerMutation('updatePAVRUserLastName'),
  UPDATE_PAVR_USER_EMAIL:                     triggerMutation('updatePAVRUserEmail'),
  UPDATE_PAVR_USER_USER_NAME:                 triggerMutation('updatePAVRUserUserName'),
  UPDATE_PAVR_USER_COMPANY:                   triggerMutation('updatePAVRUserCompany'),
  UPDATE_PAVR_USER_ADDRESS_LINE_1:            triggerMutation('updatePAVRUserAddressLine1'),
  UPDATE_PAVR_USER_ADDRESS_LINE_2:            triggerMutation('updatePAVRUserAddressLine2'),
  UPDATE_PAVR_USER_ADDRESS_LINE_3:            triggerMutation('updatePAVRUserAddressLine3'),
  UPDATE_PAVR_USER_CITY:                      triggerMutation('updatePAVRUserCity'),
  UPDATE_PAVR_USER_STATE:                     triggerMutation('updatePAVRUserState'),
  UPDATE_PAVR_USER_ZIP:                       triggerMutation('updatePAVRUserZip'),
  UPDATE_PAVR_USER_COUNTRY:                   triggerMutation('updatePAVRUserCountry'),
  UPDATE_PAVR_USER_PHONE_NUMBER:              triggerMutation('updatePAVRUserPhoneNumber'),
  UPDATE_PAVR_USER_FAX:                       triggerMutation('updatePAVRUserFax'),
  UPDATE_PAVR_USER_NEW_COUNSELOR:             triggerMutation('updatePAVRUserNewCounselor'),
  UPDATE_PAVR_USER_DATE_OF_BIRTH:             triggerMutation('updatePAVRUserDateOfBirth'),
  UPDATE_PAVR_USER_LAST_4_SSN:                triggerMutation('updatePAVRUserLast4SSN'),
  UPDATE_PAVR_USER_HOUSING_COUNSELING_AGENCY: triggerMutation('updatePAVRUserHousingCounselingAgency'),
  UPDATE_PAVR_USER_AGENCY_FHA_HCS_ID:         triggerMutation('updatePAVRUserAgencyFhaHcsId'),
  UPDATE_PAVR_USER_FHA_CONNECTION_ID:         triggerMutation('updatePAVRUserFhaConnectionId'),
  UPDATE_PAVR_USER_DISTINCT_BILLING_INFO:     triggerMutation('updatePAVRUserDistinctBillingInfo'),
  UPDATE_PAVR_USER_BILLING_FIRST_NAME:        triggerMutation('updatePAVRUserBillingFirstName'),
  UPDATE_PAVR_USER_BILLING_LAST_NAME:         triggerMutation('updatePAVRUserBillingLastName'),
  UPDATE_PAVR_USER_BILLING_EMAIL:             triggerMutation('updatePAVRUserBillingEmail'),
  UPDATE_PAVR_USER_BILLING_ADDRESS_LINE_1:    triggerMutation('updatePAVRUserBillingAddressLine1'),
  UPDATE_PAVR_USER_BILLING_ADDRESS_LINE_2:    triggerMutation('updatePAVRUserBillingAddressLine2'),
  UPDATE_PAVR_USER_BILLING_ADDRESS_LINE_3:    triggerMutation('updatePAVRUserBillingAddressLine3'),
  UPDATE_PAVR_USER_BILLING_ADDRESS_CITY:      triggerMutation('updatePAVRUserBillingAddressCity'),
  UPDATE_PAVR_USER_BILLING_ADDRESS_STATE:     triggerMutation('updatePAVRUserBillingAddressState'),
  UPDATE_PAVR_USER_BILLING_ADDRESS_ZIP:       triggerMutation('updatePAVRUserBillingAddressZip'),
  UPDATE_PAVR_USER_BILLING_ADDRESS_COUNTRY:   triggerMutation('updatePAVRUserBillingAddressCountry'),
  UPDATE_PAVR_USER_BILLING_PHONE_NUMBER:      triggerMutation('updatePAVRUserBillingPhoneNumber'),
  UPDATE_PAVR_USER_TIME_ZONE_ID:              triggerMutation('updatePAVRUserTimeZoneId')
}

const userValueMutation = function(attribute) {
  return function(state, value) {
    state.pavrUser[attribute] = value
  }
}

const userAddressValueMutation = function(attribute) {
  return function(state, value) {
    state.pavrUser.address[attribute] = value
  }
}

const userBillingAddressValueMutation = function(attribute) {
  return function(state, value) {
    state.pavrUser.billingAddress[attribute] = value
  }
}

const distinctBillingInfo = function(user) {
  return SafeGet(['organization_fields', 'distinct_billing_info'], user)
}

const billingFirstName = function(user) {
  if (distinctBillingInfo(user)) {
    return SafeGet(['organization_fields', 'billing_first_name'], user)
  } else {
    return SafeGet(['first_name'], user)
  }
}

const billingLastName = function(user) {
  if (distinctBillingInfo(user)) {
    return SafeGet(['organization_fields', 'billing_last_name'], user)
  } else {
    return SafeGet(['last_name'], user)
  }
}

const billingPhoneNumber = function(user) {
  if (distinctBillingInfo(user)) {
    return SafeGet(['organization_fields', 'billing_phone_number'], user)
  } else {
    return SafeGet(['phone_number'], user)
  }
}

const billingEmail = function(user) {
  if (distinctBillingInfo(user)) {
    return SafeGet(['organization_fields', 'billing_email'], user)
  } else {
    return SafeGet(['email'], user)
  }
}

const mutations = {
  updatePAVRUserFirstName:               userValueMutation('firstName'),
  updatePAVRUserLastName:                userValueMutation('lastName'),
  updatePAVRUserEmail:                   userValueMutation('email'),
  updatePAVRUserUserName:                userValueMutation('userName'),
  updatePAVRUserCompany:                 userValueMutation('company'),
  updatePAVRUserAddressLine1:            userAddressValueMutation('address1'),
  updatePAVRUserAddressLine2:            userAddressValueMutation('address2'),
  updatePAVRUserAddressLine3:            userAddressValueMutation('address3'),
  updatePAVRUserCity:                    userAddressValueMutation('city'),
  updatePAVRUserState:                   userAddressValueMutation('state'),
  updatePAVRUserZip:                     userAddressValueMutation('zip'),
  updatePAVRUserCountry:                 userAddressValueMutation('country'),
  updatePAVRUserPhoneNumber:             userValueMutation('phoneNumber'),
  updatePAVRUserFax:                     userValueMutation('fax'),
  updatePAVRUserNewCounselor:            userValueMutation('newCounselor'),
  updatePAVRUserDateOfBirth:             userValueMutation('dateOfBirth'),
  updatePAVRUserLast4SSN:                userValueMutation('last4SSN'),
  updatePAVRUserHousingCounselingAgency: userValueMutation('housingCounselingAgency'),
  updatePAVRUserAgencyFhaHcsId:          userValueMutation('agencyFhaHcsId'),
  updatePAVRUserFhaConnectionId:         userValueMutation('fhaConnectionId'),
  updatePAVRUserDistinctBillingInfo:     userValueMutation('distinctBillingInfo'),
  updatePAVRUserBillingFirstName:        userValueMutation('billingFirstName'),
  updatePAVRUserBillingLastName:         userValueMutation('billingLastName'),
  updatePAVRUserBillingEmail:            userValueMutation('billingEmail'),
  updatePAVRUserBillingAddressLine1:     userBillingAddressValueMutation('address1'),
  updatePAVRUserBillingAddressLine2:     userBillingAddressValueMutation('address2'),
  updatePAVRUserBillingAddressLine3:     userBillingAddressValueMutation('address3'),
  updatePAVRUserBillingAddressCity:      userBillingAddressValueMutation('city'),
  updatePAVRUserBillingAddressState:     userBillingAddressValueMutation('state'),
  updatePAVRUserBillingAddressZip:       userBillingAddressValueMutation('zip'),
  updatePAVRUserBillingAddressCountry:   userBillingAddressValueMutation('country'),
  updatePAVRUserBillingPhoneNumber:      userValueMutation('billingPhoneNumber'),
  updatePAVRUserTimeZoneId:              userValueMutation('timeZoneId'),

  setPAVRUser: function(state, user) {
    state.pavrUser = {
      id:            SafeGet(['id'], user),
      pavrOrgUserId: SafeGet(['active_org_user_id'], user),
      firstName:     SafeGet(['first_name'], user),
      lastName:      SafeGet(['last_name'], user),
      email:         SafeGet(['email'], user),
      company:       SafeGet(['organization_fields', 'company'], user),
      timeZoneId:    SafeGet(['organization_fields', 'time_zone_id'], user),
      address: {
        address1: SafeGet(['organization_fields', 'address', 'address1'], user),
        address2: SafeGet(['organization_fields', 'address', 'address2'], user),
        address3: SafeGet(['organization_fields', 'address', 'address3'], user),
        city:     SafeGet(['organization_fields', 'address', 'city'], user),
        state:    SafeGet(['organization_fields', 'address', 'state'], user),
        country:  SafeGet(['organization_fields', 'address', 'country'], user),
        zip:      SafeGet(['organization_fields', 'address', 'zip'], user)
      },
      phoneNumber:             SafeGet(['phone_number'], user),
      fax:                     SafeGet(['organization_fields', 'fax'], user),
      userName:                SafeGet(['user_name'], user),
      newCounselor:            SafeGet(['organization_fields', 'new_counselor'], user),
      dateOfBirth:             SafeGet(['date_of_birth'], user),
      last4SSN:                SafeGet(['organization_fields', 'last_4_ssn'], user),
      housingCounselingAgency: SafeGet(['organization_fields', 'housing_counseling_agency'], user),
      agencyFhaHcsId:          SafeGet(['organization_fields', 'agency_fha_hcs_id'], user),
      fhaConnectionId:         SafeGet(['organization_fields', 'fha_connection_id'], user),
      distinctBillingInfo:     distinctBillingInfo(user),
      billingFirstName:        billingFirstName(user),
      billingLastName:         billingLastName(user),
      billingPhoneNumber:      billingPhoneNumber(user),
      billingEmail:            billingEmail(user),
      billingAddress: {
        address1: SafeGet(['organization_fields', 'billing_address', 'address1'], user),
        address2: SafeGet(['organization_fields', 'billing_address', 'address2'], user),
        address3: SafeGet(['organization_fields', 'billing_address', 'address3'], user),
        city:     SafeGet(['organization_fields', 'billing_address', 'city'], user),
        state:    SafeGet(['organization_fields', 'billing_address', 'state'], user),
        country:  SafeGet(['organization_fields', 'billing_address', 'country'], user),
        zip:      SafeGet(['organization_fields', 'billing_address', 'zip'], user)
      }
    }
  }
}

export default {
  state,
  actions,
  mutations
}
