const state = {
  registrationStep: 0,
  confirmation: false,
  order: null,
  appliedCouponDetails: null
}

const actions = {
  UPDATE_PAVR_USER_REGISTRATION_STEP: function({ commit }, value) {
    commit('updatePAVRUserRegistrationStep', value.step)
    if (value.productID) commit('setProductID', value.productID)
  },
  RESET_PAVR_USER_REGISTRATION_FLOW: function({ commit }) {
    commit('resetRegistrationFlow')
  },
  CONFIRM_PAVR_USER_PURCHASE: function({ commit }, value) {
    commit('confirmPAVRUserPurchase', value)
  }
}

const mutations = {
  updatePAVRUserRegistrationStep: function(state, value) {
    state.registrationStep = value
  },
  resetRegistrationFlow: function(state) {
    state.registrationStep = 0
  },
  confirmPAVRUserPurchase: function(state, value) {
    state.registrationStep = 0
    state.order = value
    state.confirmation = true
  },
  setAppliedCouponDetails: function(state, payload) {
    state.appliedCouponDetails = payload
  }
}

export default {
  state,
  actions,
  mutations
}
