const state = {
  waiting: null
}

const actions = {

}

const mutations = {

}

export default {
  state,
  actions,
  mutations
}
