import Vue from 'vue'
import Vuex from 'vuex'

import PAVR from './modules/vendor/pavr/user'
import PAVRRegistration from './modules/vendor/pavr/registration-flow'
import PAVRReports from './modules/vendor/pavr/reports'

import PurchaseProcess from './modules/purchase'
import ExamProcess from './modules/exam'

Vue.use(Vuex)

const state = {
  user: null,
  organization: null
}

export default new Vuex.Store({
  state,
  modules: {
    PAVR,
    PAVRRegistration,
    PAVRReports,
    PurchaseProcess,
    ExamProcess
  },
  actions: {
    SET_USER: function({ commit, state }, user) {
      commit('setUser', user)
    },
    SET_ORGANIZATION: function({ commit }, organization) {
      commit('setOrganization', organization)
    }
  },
  mutations: {
    setUser: function(state, user) {
      state.user = user
    },
    setOrganization: function(state, organization) {
      state.organization = organization
    }
  }
})
